/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { Layout } from '@oneaudi/unified-web-components';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { convertToSanitzedString } from './editorialTableUtils';
import { reconstructWithDesignComponents } from '../reconstructWithDesignComponents/reconstructWithDesignComponents';
import { Container } from './style';

export interface EditorialTableTableContent {
  bodyTable: string;
  theme: string;
  isOneColTable: boolean;
}

export interface Props {
  content: EditorialTableTableContent;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
  trackingService?: TrackingServiceV2;
  tableRef?: any;
}

export const EditorialTable = ({
  content,
  footnoteReferenceService,
  trackingService,
  tableRef,
}: Props) => {
  const { bodyTable, theme, isOneColTable } = content;
  const sanitzizedString = convertToSanitzedString(bodyTable);

  const dataForTable = reconstructWithDesignComponents(sanitzizedString, isOneColTable);

  const getUrlExtension = (url: string) => {
    return url?.split(/[#?]/)[0].split('.')?.pop()?.trim();
  };

  const setEventAction = (ctaUrl: string) => {
    const isExternalURL = (url: string) => new URL(url).origin !== window.location.origin;
    if (getUrlExtension(ctaUrl) === 'pdf' || getUrlExtension(ctaUrl) === 'xlsx') return 'download';

    if (ctaUrl === '') return 'content';

    if (isExternalURL(ctaUrl)) return 'exit_link';

    return 'internal_link';
  };

  if (trackingService) {
    trackingService.featureAppName = 'editorial table';
  }

  const addTrackingClickEvent = (cta: HTMLAnchorElement) => {
    const url = cta.href;

    const getCtaColumnPos = () => {
      const trArray: HTMLTableRowElement[] = tableRef.current.querySelectorAll('tr');
      let index: number = 0;
      trArray.forEach((tr: HTMLTableRowElement) => {
        if (tr.contains(cta)) {
          tr.childNodes.forEach((tdNode) => {
            if (tdNode.contains(cta)) {
              const list = Array.from(tr.childNodes);
              index = list.indexOf(tdNode) + 1;
            }
          });
        }
      });
      return `${index}`;
    };

    const dataLayerEvent: any = {
      eventInfo: {
        eventAction: setEventAction(url),
        eventName: `editorial table - click ${setEventAction(url) === 'download' ? 'to download' : 'on text link'}`,
      },
      attributes: {
        componentName: 'editorial-table',
        label: cta.innerText,
        targetURL: url,
        clickID: '',
        elementName: 'text link',
        value: cta.parentElement?.previousElementSibling?.innerHTML
          ? cta.parentElement?.previousElementSibling?.innerHTML
          : '',
        pos: getCtaColumnPos(),
        relatedElements:
          getUrlExtension(url) === 'pdf' || getUrlExtension(url) === 'xlsx'
            ? {
                elementName: `${getUrlExtension(url)} file`,
                elementValue: cta.parentElement?.previousElementSibling?.innerHTML,
              }
            : [cta.innerHTML],
      },
    };

    cta.onclick = () => {
      trackingService?.track({
        event: dataLayerEvent,
      });
    };
  };

  useEffect(() => {
    const ctaList = tableRef?.current?.querySelectorAll('a');
    if (ctaList) {
      ctaList.forEach((cta: HTMLAnchorElement) => {
        addTrackingClickEvent(cta);
      });
    }
  }, [tableRef?.current]);

  return (
    <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService || {}}>
      {bodyTable && (
        <Container data-testid="container" theme={theme} isOneColTable={isOneColTable}>
          <Layout direction="column" ref={tableRef}>
            {dataForTable}
          </Layout>
        </Container>
      )}
    </FootnoteContextProvider>
  );
};

export default EditorialTable;
