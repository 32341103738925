/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */

import React, { useEffect, useRef, useState } from 'react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import {
  ContentServiceV1 as ContentService,
  ContentChangeListener,
} from '@volkswagen-onehub/audi-content-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { useInViewEffect } from '@oneaudi/feature-app-utils';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import { ThemeName } from '@oneaudi/unified-web-common/dist/cjs/components/theme-provider/theme';
import EditorialTable, {
  EditorialTableTableContent,
} from './components/EditorialTable/EditorialTable';

export interface AppContent {
  __type?: 'default';
  fields: {
    theme: string;
    bodyTable: string;
    isOneColTable: boolean;
  };
}

interface FeatureAppProps {
  configProvider?: GfaServiceConfigProviderV1;
  contentService?: ContentService;
  trackingService?: TrackingServiceV2;
  content?: EditorialTableTableContent;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  let trackingService = {} as TrackingServiceV2;
  const tableRef = useRef<HTMLDivElement | null>(null);

  if (typeof window !== 'undefined') {
    trackingService = inherited.trackingService!;
  }

  const { contentService, footnoteReferenceService } = inherited;

  const [content, setContent] = useState(contentService?.getContent() as AppContent);

  useEffect(() => {
    trackingService.featureAppName = 'editorial-table';
    const additionalObjects = {
      componentInfo: {
        componentID: 7,
      },
      attributes: {
        implementer: 2,
        version: __FEATURE_APP_VERSION__,
      },
    };

    const dataLayerEvent = {
      eventInfo: {
        eventAction: 'feature_app_ready',
        eventName: 'editorial table - feature app ready',
      },
      attributes: {
        componentName: 'editorial-table',
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: '',
        pos: '',
      },
    };
    trackingService.track({ event: dataLayerEvent, componentUpdate: additionalObjects });
  }, []);

  contentService?.onContentChange(setContent as ContentChangeListener);
  const { theme } = content.fields;

  useInViewEffect(() => {
    const getColNumbers = () => {
      const table = tableRef.current?.querySelector('table');
      const numberOfCol = table?.querySelector('tbody')?.firstChild?.childNodes.length;
      return `${numberOfCol}`;
    };
    const dataLayerEvent = {
      eventInfo: {
        eventAction: 'impression',
        eventName: 'editorial table - impression',
      },
      attributes: {
        componentName: 'editorial-table',
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: tableRef.current?.querySelector('caption')?.innerText,
        pos: getColNumbers(),
      },
    };
    trackingService.track({ event: dataLayerEvent });
  });
  return (
    <ThemeProvider theme={{ name: theme as ThemeName }}>
      <EditorialTable
        content={content.fields}
        footnoteReferenceService={footnoteReferenceService}
        trackingService={trackingService}
        tableRef={tableRef}
      />
    </ThemeProvider>
  );
};

export default FeatureApp;
