/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import {
  Divider,
  Table,
  TableHeader,
  TableBody,
  TableCell,
  TableColumnHeadingCell,
  TableRow,
  Text,
  Headline,
  TextLink,
  TextList,
  TextListItem,
  TableRowHeadingCell,
  RichText,
} from '@oneaudi/unified-web-components';
import * as prod from 'react/jsx-runtime';
import type { Options } from 'rehype-react';
import type { ReactElement, JSXElementConstructor, ReactNode } from 'react';
import styled from 'styled-components';

export interface TableDataContent {
  captionData?: string | undefined;
  headingsData?: React.JSX.Element[] | null;
  contentData: React.JSX.Element[][];
  key: string;
}

export const reconstructWithDesignComponents = (contentString: string, isOneColTable: boolean) => {
  const defaultOptions = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };
  const isHtml = /<[a-z/][\s\S]*>/i;

  const rehypeReactOptions = {
    ...defaultOptions,
    components: {
      h1(props: React.PropsWithChildren) {
        if (
          typeof (props?.children as any).key === 'string' &&
          (props?.children as any).key.includes('strong')
        ) {
          return (
            <Headline tag="h1" variant={{ type: 'headline', order: '1', weight: 'bold' }}>
              {processChild((props as any)?.children?.props?.children)}
            </Headline>
          );
        }
        return (
          <Headline tag="h1" variant={{ type: 'headline', order: '1', weight: 'normal' }}>
            {processChildren(props.children)}
          </Headline>
        );
      },
      h2(props: React.PropsWithChildren) {
        if (
          typeof (props?.children as any).key === 'string' &&
          (props?.children as any).key.includes('strong')
        ) {
          return (
            <Headline tag="h2" variant={{ type: 'headline', order: '2', weight: 'bold' }}>
              {processChild((props as any)?.children?.props?.children)}
            </Headline>
          );
        }
        return (
          <Headline tag="h2" variant={{ type: 'headline', order: '2', weight: 'normal' }}>
            {processChildren(props.children)}
          </Headline>
        );
      },
      h3(props: React.PropsWithChildren) {
        if (
          typeof (props?.children as any).key === 'string' &&
          (props?.children as any).key.includes('strong')
        ) {
          return (
            <Headline tag="h3" variant={{ type: 'headline', order: '3', weight: 'bold' }}>
              {processChild((props as any)?.children?.props?.children)}
            </Headline>
          );
        }
        return (
          <Headline
            tag="h3"
            variant={{ type: 'headline', order: '3', weight: 'normal' }}
            data-testid="headline"
          >
            {processChildren(props.children)}
          </Headline>
        );
      },
      h4(props: React.PropsWithChildren) {
        if (
          typeof (props?.children as any).key === 'string' &&
          (props?.children as any).key.includes('strong')
        ) {
          return (
            <Headline tag="h4" variant={{ type: 'headline', order: '4', weight: 'bold' }}>
              {processChild((props as any)?.children?.props?.children)}
            </Headline>
          );
        }
        return (
          <Headline tag="h4" variant={{ type: 'headline', order: '4', weight: 'normal' }}>
            {processChildren(props.children)}
          </Headline>
        );
      },
      p(props: React.PropsWithChildren) {
        return (
          <Text tag="p" variant={{ order: '1', style: 'normal', type: 'copy' }}>
            {processChildren(props.children)}
          </Text>
        );
      },
      div(props: React.PropsWithChildren) {
        return <div>{processChildren(props.children)}</div>;
      },
      span(props: React.PropsWithChildren) {
        return (
          <Text tag="span" variant={{ order: '1', style: 'normal', type: 'copy' }}>
            {processChildren(props.children)}
          </Text>
        );
      },
      ul(props: React.PropsWithChildren) {
        return <TextList variant="bullet">{processChildren(props.children)}</TextList>;
      },
      ol(props: React.PropsWithChildren) {
        return <TextList variant="decimal">{processChildren(props.children)}</TextList>;
      },
      li(props: React.PropsWithChildren) {
        return <TextListItem>{processChildren(props.children)}</TextListItem>;
      },
      hr() {
        return <Divider />;
      },
      blockquote({ children, ...props }: React.PropsWithChildren) {
        return (
          <blockquote {...props}>
            <Text tag="p" variant={{ order: '1', style: 'normal', type: 'copy' }}>
              {processChildren(children)}
            </Text>
          </blockquote>
        );
      },
      pre(props: React.PropsWithChildren) {
        return (
          <Text tag="span">
            <pre>{processChildren(props.children)}</pre>
          </Text>
        );
      },
      a({ children, ...props }: React.PropsWithChildren) {
        const { href, target } = props as Props;
        return (
          <TextLink href={href as string} target={target} size="md" variant="inline">
            {processChildren(children)}
          </TextLink>
        );
      },
      strong({ children }: React.PropsWithChildren) {
        return (
          <Text tag="span" variant={{ order: '1', style: 'bold', type: 'copy' }}>
            {processChildren(children)}
          </Text>
        );
      },
      table({ children }: React.PropsWithChildren) {
        const isTableComplete = Array.isArray(children);
        const caption: string = isTableComplete
          ? (children as Children[])?.filter((child) => child.type === 'caption')[0]?.props.children
          : null;

        const thead = isTableComplete
          ? (children as Children[])?.filter((child) => child.key.includes('thead'))[0]
          : null;

        const theadText = isTableComplete
          ? (thead as unknown as any)?.props?.children?.props?.children.map((child: any) => {
              return child.props.children;
            })
          : null;

        const tbody = isTableComplete
          ? (children as Children[])?.filter((child) => child.key.includes('tbody'))?.[0]
          : children;

        return (
          <Table caption={caption} data-testid="table">
            <TableHeader>
              <TableRow>
                {thead?.props?.children?.props?.children.map((column: any) => {
                  if (
                    processChildren(column.props.children) &&
                    (processChildren(column.props.children) as undefined as any).length === 1
                  ) {
                    return <TableCell key={`tableColumnHeading-${Math.random()}`} />;
                  }

                  return (
                    <TableColumnHeadingCell key={`tableColumnHeading-${Math.random()}`}>
                      <StyledTh style={column.props.style}>
                        {processChildren(column.props.children)}
                      </StyledTh>
                    </TableColumnHeadingCell>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody>
              {isOneColTable &&
                tbody &&
                (tbody as any).props.children.map((row: any, index: number) => {
                  return (
                    <TableRow>
                      <TableCell
                        columnHeading={theadText?.[index]}
                        key={`tableCell-${Math.random()}`}
                      >
                        <StyledTd style={row?.props?.style}>
                          {processChildren(row.props.children.props.children)}
                        </StyledTd>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!isOneColTable &&
                (tbody && Array.isArray((tbody as any).props.children) ? (
                  (tbody as any).props.children.map((row: any) => {
                    return (
                      <TableRow key={`tableRow-${Math.random()}`}>
                        {row.props.children.map((cell: any, index: number) => {
                          if (cell.key.includes('th'))
                            return (
                              <TableRowHeadingCell key={`tableRowHeadingCell-${Math.random()}`}>
                                <StyledTh style={cell?.props?.style}>
                                  {processChildren(cell.props.children)}
                                </StyledTh>
                              </TableRowHeadingCell>
                            );
                          return (
                            <TableCell
                              columnHeading={theadText?.[index]}
                              key={`tableCell-${Math.random()}`}
                            >
                              <StyledTd style={cell?.props?.style}>
                                {processChildren(cell.props.children)}
                              </StyledTd>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow key={`tableRow-${Math.random()}`}>
                    {(tbody as any).props.children.props.children.map(
                      (cell: any, index: number) => {
                        if (cell.key.includes('th'))
                          return (
                            <TableRowHeadingCell key={`tableRowHeadingCell-${Math.random()}`}>
                              <StyledTh style={cell?.props?.style}>
                                {processChildren(cell.props.children)}
                              </StyledTh>
                            </TableRowHeadingCell>
                          );
                        return (
                          <TableCell
                            columnHeading={theadText?.[index]}
                            key={`tableCell-${Math.random()}`}
                          >
                            <StyledTd style={cell?.props?.style}>
                              {processChildren(cell.props.children)}
                            </StyledTd>
                          </TableCell>
                        );
                      },
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        );
      },
    },
  };

  const processChildren = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: string | ReactNode | ReactElement<any, string | JSXElementConstructor<any>>[],
  ) => {
    if (typeof children === 'string' && !isHtml.test(children)) {
      return processChild(children);
    }
    if (typeof children === 'string' && isHtml.test(children)) {
      return <RichText markdown={children} />;
    }

    if (Array.isArray(children)) {
      return children.map(
        (child) => child.type !== 'caption' && processChild(child as unknown as string),
      );
    }

    if (children && Array.isArray((children as any)?.props.children)) {
      return (children as any).props.children.map((child: any) => processChild(child));
    }

    return children;
  };
  const processChild = (value: string) => {
    if (typeof value === 'string' && value.match(/({ft_[a-z0-9-_]*})/gi)) {
      return renderTextWithFootnotesReferencesV2(value);
    }
    if (typeof value === 'string' && isHtml.test(value)) {
      return <RichText markdown={value} />;
    }

    return value;
  };

  const file = unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(rehypeReact, rehypeReactOptions as unknown as Options)
    .processSync(contentString);

  return file.result;
};
interface Props {
  style: { [key: string]: string };
  href?: string;
  target?: string;
}
interface Children {
  type: string;
  key: string;
  props: {
    children: any;
  };
}
const StyledTd = styled.span<Props>`
  display: block;
  height: auto !important;
  ${(props) => props.style}

  em {
    font-style: italic !important;
  }
`;

const StyledTh = styled.span<Props>`
  ${(props) => props.style}
  display: block;
  height: auto !important;
`;
